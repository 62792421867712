import React from 'react'

const SEMBLER_URL = 'https://isembler.ru/sembler/images/'
const se_regexp = new RegExp(SEMBLER_URL, 'gi')

function SemblerImage(props) {
  const { src, ...rest } = props
  return (
    <img
      src={`${SEMBLER_URL}${String(src).replace(se_regexp, '')}`}
      alt={src}
      {...rest}
    />
  )
}

export { SemblerImage }
